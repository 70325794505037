/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { StaticImage } from "gatsby-plugin-image"
import "./layout.css"

const Layout = ({ children }) => {

  return (
    <>
      <div
        id="coming-soon"
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem`,
        }}
      >
        <main>{children}</main>
        <footer
          style={{
            marginTop: `2rem`,
          }}
        >
          <StaticImage
            src="../images/gatsby-icon.png"
            width={25}
            quality={95}
            placeholder="none"
            formats={["auto", "webp", "avif"]}
            alt="Michael Boyko | Web Developer Portfolio"
          />
          <div className="footer-title">ichael Boyko | Web Developer Portfolio</div>
          <div className="copyright">&copy; {new Date().getFullYear()}</div>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
